<template>
   <v-expand-transition>
      <v-form ref="form">
         <div v-if="typeof activity.id === 'string' || activity.id >= 0">
            <v-card-text class="pt-9">
               <v-row>
                  <v-col cols="12" md="6">
                     <v-text-field v-model="form.name" label="Visitor Name" placeholder="Nama Lengkap" outlined hide-details required :rules="[v => !!v]">
                        <template slot="append-outer">
                           <v-btn large height="56" width="70" icon tile :class="{'error--text':invalid!=null&&!form.Card}" @click="$refs.activityCard.click()">
                              <!-- @click="form.id === 0 ? $refs.activityCard.click() : null" -->
                              <v-avatar v-if="form.Card" tile height="54" width="70">
                                 <img :src="setUrlFromImage(form.Card)" alt="Card" style="object-fit: cover;" @error="() => { form.Card = '' }">
                              </v-avatar>
                              <v-icon v-else tile size="84">
                                 mdi-image-area
                              </v-icon>
                              <input ref="activityCard" type="file" accept="image/*" alt="activityCard" style="display:none;" @change="attachmentPreview">
                           </v-btn>
                           <v-btn large height="56" width="56" icon class="ml-3" :class="{'error--text':invalid!=null&&!form.Face}" @click="$refs.activityFace.click()">
                              <!-- @click="form.id === 0 ? $refs.activityFace.click() : null" -->
                              <v-avatar v-if="form.Face" size="56">
                                 <img :src="setUrlFromImage(form.Face)" alt="Face" style="object-fit: cover;" @error="() => { form.Face = '' }">
                              </v-avatar>
                              <v-icon v-else size="64">
                                 mdi-account-circle
                              </v-icon>
                              <input ref="activityFace" type="file" accept="image/*" alt="activityFace" style="display:none;" @change="attachmentPreview">
                           </v-btn>
                        </template>
                     </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.phone" label="Phone" placeholder="Nomor Handphone" outlined hide-details required :rules="[v => !!v]" />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.email" label="Email" placeholder="Alamat Email" outlined hide-details required :rules="[v => !v || /^\w+([-\+\.]?\w+)*@\w+([-\.]?\w+)*(\.\w{2,4})+$/.test(v)]" />
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field v-if="form.id !== 0" label="Tenant" outlined hide-details readonly
                        :value="(organizations.find(j => j.identity === form.organization) || { name: form.organization }).name"
                        />
                     <v-autocomplete v-else :items="filteredOrganizations" label="Tenant" placeholder="Ketik lalu PILIH" outlined hide-details required :rules="[v => !!v]"
                        item-text="name" item-value="identity" no-data-text="Typeahead and choose one"
                        prepend-inner-icon="mdi-database-search"
                        :return-object="true" :menu-props="{ offsetY: true, bottom: true }"
                        :search-input.sync="search.input"
                        @change="changeOrganization"
                        />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-if="form.id !== 0" label="Floor" outlined hide-details readonly
                        :value="(floors.find(j => j.identity === form.floor) || { name: form.floor }).name"
                        />
                     <v-select v-else v-model="form.floor" :items="filteredFloors" label="Floor" placeholder="Pilih Lantai" outlined hide-details required :rules="[v => !!v]"
                        item-text="name" item-value="identity" :disabled="!filteredFloors.length"
                        :return-object="false" :menu-props="{ offsetY: true, bottom: true }"
                        />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-combobox v-model="form.reason" :items="reasons" label="Reason" placeholder="Visiting Purpose" outlined hide-details required :rules="[v => !!v]"
                        item-text="name" item-value="name" :return-object="false"
                        :menu-props="{ offsetY: true }"
                        @input.native="form.reason=$event.srcElement.value"
                        />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" md="3">
                     <v-menu v-model="since.dateMenu" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                           <v-text-field :value="dateSince" placeholder="Valid Date" outlined hide-details required :rules="[v => !!v]" readonly
                              prepend-inner-icon="mdi-calendar-today" v-bind="attrs" v-on="on"
                              />
                        </template>
                        <v-date-picker v-model="since.dateValue" :first-day-of-week="1" @change="since.dateMenu = false" />
                     </v-menu>
                  </v-col>
                  <v-col cols="12" md="2">
                     <v-menu ref="sinceMenuTime" v-model="since.timeMenu" :close-on-content-click="false" min-width="290px" max-width="290px"
                        :nudge-right="40" :return-value.sync="since.timeValue"
                        transition="scale-transition" offset-y
                        >
                        <template v-slot:activator="{ on, attrs }">
                           <v-text-field v-model="since.timeValue" placeholder="Valid Time" outlined hide-details required :rules="[v => !!v]" readonly
                              prepend-inner-icon="mdi-clock-outline" :disabled="!since.dateValue"
                              v-bind="attrs" v-on="on"
                              />
                        </template>
                        <v-time-picker v-if="since.timeMenu" v-model="since.timeValue" full-width format="24hr" use-seconds @click:second="$refs.sinceMenuTime.save(since.timeValue)" />
                     </v-menu>
                  </v-col>
                  <!-- <v-col cols="12" md="3">
                     <v-text-field :value="dateSince" placeholder="Until Date" outlined hide-details required :rules="[v => !!v]" readonly
                        prepend-inner-icon="mdi-calendar-today" disabled
                        />
                  </v-col> -->
                  <v-col cols="12" md="2">
                     <v-menu ref="untilMenuTime" v-model="until.timeMenu" :close-on-content-click="false" min-width="290px" max-width="290px"
                        :nudge-right="40" :return-value.sync="until.timeValue"
                        transition="scale-transition" offset-y
                        >
                        <template v-slot:activator="{ on, attrs }">
                           <v-text-field v-model="until.timeValue" placeholder="Until Time" outlined hide-details required :rules="[v => !!v]" readonly
                              prepend-inner-icon="mdi-clock-outline" :disabled="!since.dateValue"
                              v-bind="attrs" v-on="on"
                              />
                        </template>
                        <v-time-picker v-if="until.timeMenu" v-model="until.timeValue" full-width format="24hr" use-seconds @click:second="$refs.untilMenuTime.save(until.timeValue)" />
                     </v-menu>
                  </v-col>
                  <v-col cols="12" md="5">
                     <v-text-field v-model="form.message" label="Message" placeholder="Catatan" outlined hide-details />
                  </v-col>
               </v-row>
               <v-row v-if="form.id !== 0">
                  <v-col cols="12">
                     <v-simple-table dense>
                        <template v-slot:default>
                           <thead>
                              <tr>
                                 <th style="width: 120px;">
                                    Activity
                                 </th>
                                 <th style="width: 190px;">
                                    Date Time
                                 </th>
                                 <th style="width: 190px;">
                                    Operator Code
                                 </th>
                                 <th class="text-left">
                                    Remark
                                 </th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="(item, idx) in form.Activities" :key="idx">
                                 <td>
                                    {{ item.name }}
                                 </td>
                                 <td>
                                    {{ item.since }}
                                 </td>
                                 <td>
                                    {{ item.operator }}
                                 </td>
                                 <td>
                                    {{ item.name === 'Rejected' ? form.remark : '' }}
                                 </td>
                              </tr>
                           </tbody>
                        </template>
                     </v-simple-table>
                     <v-divider />
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
            <v-card-text class="pb-6">
               <v-row class="mt-0">
                  <v-col cols="12" class="py-0 d-flex">
                     <v-select v-model="form.activity" :items="approvals" label="Activity" placeholder="Pilih Aktivitas" outlined hide-details class="mr-4" style="margin-top: -2px; max-width: 290px;"
                        :return-object="false" :menu-props="{ bottom: true, offsetY: true }"
                        />
                     <v-btn v-if="$hasRole(activity.kind.toLowerCase(), 'CU')" x-large color="accent" depressed class="mr-4"
                        :loading="$store.state.process === 'updateApproval'" @click="updateRecord"
                        >
                        <!-- :disabled="(!approvals.some(j => !j.disabled) || !form.activity) && form.id !== 0" -->
                        Update
                     </v-btn>
                     <v-btn x-large color="accent" depressed outlined class="mr-4" @click="activity.id = -1">
                        {{ $store.state.process === 'updateApproval' ? 'Close' : 'Cancel' }}
                     </v-btn>
                     <v-spacer />
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
         </div>
      </v-form>
   </v-expand-transition>
</template>

<script>
import { imageFromUrl, blobImageToBase64 } from '@/utils/nirtara' //, flatten
import { approval } from '@/utils/fields.js'
export default {
   name: 'NApprovalForm',

   props: {
      activity: { type: Object },
      searchs: { type: Function },
      sequences: { type: Array },
      organizations: { type: Array },
      floors: { type: Array },
      reasons: { type: Array },
   },
   data: () => ({
      isDebugging: false,
      isUsed: false,
      form: Object.assign({}, approval),
      search: { input: null, organization: {}, organizations: [] },
      select: { floor: [], floors: [] },
      invalid: null,
      since: {
         dateMenu: false,
         dateValue: null,
         timeMenu: false,
         timeValue: null,
      },
      until: {
         timeMenu: false,
         timeValue: null,
      },
   }),

   computed: {
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
      filteredOrganizations () {
         return (this.search.input && this.search.input.length > 0)
            ? this.organizations.filter(j => j.Receptionists.length)
            : []
      },
      filteredFloors () {
         const floors = (this.search.organization.Floors || []).map(j => j.identity)
         return this.floors.filter(j => floors.includes(j.identity))
      },
      dateSince () {
         return this.since.dateValue ? this.$moment(this.since.dateValue).format('dddd, YYYY-MM-DD') : ''
      },
   },
   watch: {
      'activity.id' (id) {
         if (id < 0) return
         id === 0 && this.$refs.form.reset()
         id === 0 && (this.invalid = null)
         const { form, activity, since, until } = this // , search, select
         // console.log('watch (activity)', JSON.parse(JSON.stringify(activity)))
         // console.log('watch (form)', JSON.parse(JSON.stringify(form)))
         // // let possibilities = []
         since.dateValue = since.timeValue = until.dateValue = until.timeValue = ''
         if (id === 0) {
            // // this.approvals.find(j => j.text === 'Register').disabled = false
            // // form.activity = 'Register'
            // Object.assign(form, approval, { id: 0, activity: 'Register', Activities: [] })
            Object.assign(form, approval, { id: 0, activity: 'Register' }, this.isDebugging ? { name: '11', phone: '22', email: '33@33.33', reason: '44' } : null)

            const now = this.$moment().add((30 - (this.$moment().minute() % 30)), 'minutes')
            since.dateValue = now.format('YYYY-MM-DD')
            since.timeValue = now.format('HH:mm:00')
            until.timeValue = now.add(3, 'hours').format('HH:mm:00')
         } else {
            Object.assign(form, approval, activity)

            form.valid && form.valid.split(' ').map(s => s.length === 10 ? (since.dateValue = s) : (since.timeValue = s))
            form.until && form.until.split(' ').map(s => s.length === 10 ? (since.dateValue = s) : (until.timeValue = s))
            // this.$nextTick(() => {
            //    this.search.input = (this.organizations.find(j => j.identity === form.organization) || { name: '' }).name.toLowerCase()
            //    /*
            //    const emails = search.input.split(/\r?\n|,| /).map(s => s.trim()).filter(email => {
            //       const idx = search.emails.findIndex(e => e === email)
            //       return idx >= 0 ? false : this.rules.test(email)
            //    })
            //    search.emails.push(...emails)
            //    this.$nextTick(() => { search.input = null; this.invalid = false })
            //    */
            // })

            // console.log('watch (this.search.input)', JSON.parse(JSON.stringify(this.search.input)))
            // const organization = this.organizations.find(j => j.identity === form.organization) || { name: form.organization, identity: form.organization }
            // // this.search.input = organization.name
            // this.changeOrganization(organization)
         // this.search.organization = item
         // this.select.floor = {}
         // item && (this.select.floors = item.Floors.map(j => (this.floors.find(f => f.identity === j.identity) || { name: j.identity, identity: j.identity })))

            /*
            // console.log('watch (this.sequences)', JSON.parse(JSON.stringify(this.sequences)))
            possibilities = this.sequences.map(j => {
               const idx = j.Flows.findIndex(f => activity.activity === f.name)
               if ((j.Flows[idx + 1] || {}).code === this.$store.state.user.code) return j.Flows[idx + 1].name
            }).filter((value, index, self) => value && self.indexOf(value) === index)
            // console.log(`watch ( activity.activity = ${activity.activity}, this.$store.state.user.code = ${this.$store.state.user.code}, possibilities = ${possibilities.length} )`, JSON.parse(JSON.stringify(possibilities)))
            // possibilities.length === 1 && (form.activity = possibilities[0])
            // form.activity = possibilities.length === 1 ? possibilities[0] : ''
            */
         }
         /*
         this.approvals.map(j => {
            j.disabled = id === 0 || !possibilities.includes(j.text)
         })
         const enabled = this.approvals.find(j => !j.disabled)
         enabled && (form.activity = enabled.text)
         // console.log('watch (form)', JSON.parse(JSON.stringify(form)))
         // console.log(`watch (${this.approvals.some(j => !j.disabled)})`, JSON.parse(JSON.stringify(this.approvals)))
         */
      },
   },
   created () {
      this.approvals = [
         { text: 'Enrolled', disabled: true }, // By TNT
         { text: 'Register', disabled: true }, // By VST | RCP
         { text: 'Rejected' }, // , disabled: true -> By RCP | TNT
         { text: 'Accepted' }, // , disabled: true -> By TNT
      ]
   },
   mounted () {
   },
   methods: {
      changeOrganization (item) {
         // console.log('changeOrganization (item)', item && JSON.parse(JSON.stringify(item)))
         this.search.organization = item
         this.select.floor = {}
         item && (this.select.floors = item.Floors.map(j => (this.floors.find(f => f.identity === j.identity) || { name: j.identity, identity: j.identity })))
      },
      attachmentPreview (event) {
         blobImageToBase64(this.$refs[event.target.alt].files[0])
         .then(base64 => {
            switch (event.target.alt) {
               default: console.log(`${event.target.alt}: ${base64.substr(0, 32)}....`)
                  break
               case 'activityCard': this.form.Card = base64
                  break
               case 'activityFace': this.form.Face = base64
                  break
            }
         })
      },
      insertUnit () {
         /*
         const { units, search, select } = this
         units.push({ Unit: [], identity: search.unit.identity, name: search.unit.name, zone: search.unit.zone, code: select.code })
         search.unit = select.code = ''
         */
      },
      searchRecord (type, kind) {
         console.log(`searchRecord ( ${type}, ${kind} )`)
         /*
         var search = this.search[kind.toLowerCase()]
         if (!search) return
         if (typeof search === 'string' && search.length < 2) return
         if (typeof search === 'object') search = search.name

         this.searchs(type, { kind: kind, zone: this.form.id === 0 ? '*' : this.$store.state.user.zone, 'object.name': { $regex: `.*${search}.*`, $options: 'i' } }, result => {
            // this.search[`${kind.toLowerCase()}s`] = result // .map(json => Object.assign({}, template, flatten(json)))
            this.search[`${kind.toLowerCase()}s`] = result.map(json => Object.assign(json, { View: json[kind].length ? json[kind][0].View[0] : '' }))
            this.search[`${kind.toLowerCase()}s`].length === 0 && this.$store.commit('snackNotification', { Message: [{ Warning: [], note: `Search '${search}' found 0 records` }], kind: 'Warning' })
         })
         */
      },
      updateRecord () {
         this.invalid = !this.$refs.form.validate()
         if (this.invalid) return
         // const { form } = this
         // const ipV4 = this.$store.state.ipV4 || (await (await fetch('https://api.ipify.org?format=json')).json()).ip
         // const form = Object.assign({}, this.form, {
         //    organization: this.search.organization.identity,
         //    Activities: [{ Activity: [], name: 'Register', since: this.$moment().format('YYYY-MM-DD HH:mm:ss'), operator: this.$store.state.user.identity || 'None', ipV4: this.$store.state.ipV4 }],
         //    // valid: '', // this.$moment().format('YYYY-MM-DD HH:mm:ss'),
         //    // until: this.$moment().format('YYYY-MM-DD 19:00:00'),
         // })

         const form = JSON.parse(JSON.stringify(this.form))
         form.Activities.unshift({ Activity: [], name: form.activity, since: this.$moment().format('YYYY-MM-DD HH:mm:ss'), operator: 'Self', ipV4: this.$store.state.ipV4 })
         !form.organization && (form.organization = this.search.organization.identity) // Register
         if (form.activity === 'Accepted') {
            const valid = form.valid || this.$moment().format('YYYY-MM-DD HH:mm:ss')
            form.valid = valid
            form.until = `${valid.slice(0, 10)} 19:00:00`
         }

         // console.log('updateRecord (form)', JSON.parse(JSON.stringify(form)))
         this.$emit('update', form)

         /*
         if (!this.$refs.form.validate() || !this.units.length) return
         const { form, units } = this
         // console.log('updateRecord (form)', JSON.parse(JSON.stringify(form)))
         // console.log('updateRecord (units)', JSON.parse(JSON.stringify(units)))
         const activities = [{ Activity: [], name: form.activity, since: this.$moment().format('YYYY-MM-DD HH:mm:ss'), operator: this.$store.state.user.identity, ipV4: this.$store.state.ipV4 }]
         form.Activities && activities.push(...form.Activities)
         // console.log('updateRecord (activities)', JSON.parse(JSON.stringify(activities)))
         form.activity === 'Register' && activities.length === 1 && (form.zone = '*')
         // Object.assign(form, { Units: units, Activities: activities }) // OK
         // console.log('updateRecord (form)', JSON.parse(JSON.stringify(form)))
         // this.$emit('update', this.form) // OK
         this.$emit('update', Object.assign({}, form, { Units: units, Activities: activities }))
         */
      },
   },
}
</script>

<style lang="scss" scoped>
.col-12 {
   padding: 0 12px 20px 12px;
}
::v-deep .v-text-field--outlined .v-input__append-outer {
   margin: auto 0 auto 16px;
}
.v-chip.v-chip--outlined .v-icon {
   font-size: 36px !important;
}
::v-deep .v-chip .v-chip__close.v-icon {
   font-size: 22px !important;
}
.v-chip .v-avatar {
   height: 40px !important;
   min-width: 56px !important;
   width: 56px !important;
   margin-right: 10px;
}
::v-deep .v-chip .v-chip__content {
   letter-spacing: 0;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
   font-size: 1.05rem;
   letter-spacing: 0;
   font-weight: 300;
}
::v-deep .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 1.0rem;
   letter-spacing: 0;
}
</style>
